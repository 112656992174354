.navbar{
    background: var(--dark-bg)!important;
    /* border-bottom: 0.5px solid rgba(0, 0, 0, 0.2); */
    box-shadow: var(--box-shadow-2);
    display: flex;
    align-items: center;
    padding: 1rem!important;
    position: fixed;
    flex-wrap: wrap;
    z-index: 3;
    width: -webkit-fill-available;
    width: -moz-available;
    /* justify-content: flex-start; */
    justify-content: space-between;
    color: var(--dark-primary-color);
    transition: 0.5s;
    top: 0;
    /* gap: 7px; */
}
.navbar.light{
    background: var(--light-bg)!important;
}
.navbar a{
    display: contents;
    text-decoration: none;
    color: var(--dark-primary-color);
}
.navbar .topmenu{
    cursor: pointer;
    user-select: none;
    display: none;
}
.navbar p{
    padding: 0;
    margin: 0;
    color: var(--dark-primary-color);
    font-weight: 700;
    font-size: 1rem;
}
.toggle_button{
    cursor: pointer;
    height: 1.2rem;
    width: 1.2rem;
}
.effect_icon{
    height: 1.5rem;
    width: 1.5rem;
}

.map_page img{
    width: 24px;
    cursor: pointer;
    /* height: 30px; */
    /* background-position: center;
    background-size: cover; */
    /* background-image: url(../../assets/images/india.png); */
}
.inc-size{
    height: 1.3rem;
    width: 1.3rem;
}