.locationchart{
    /* width: 730px; */
    height: 480px;
    transition: 0.5s;
    background: #272B44;
    border-radius: 6px;
    margin: 1rem auto;
    /* height: 25.5rem!important; */
    display: flex;
    align-items: flex-end;
}
.light .locationchart{
    box-shadow: var(--box-shadow-1);
    background: #fff;
    color: var(--box-text-light-primary);
} 
.locationchart p{
    margin: 0;
}
.locationchart .titleItem p{
    
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}
.locationchart .titleItem span{
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
}
.locationchart .titleItem{
    display: flex;
    align-items: baseline;
    gap: 12px;
    padding: 1rem;
}
@media screen and (max-width:480px) {
    .locationchart .titleItem{
        flex-wrap: wrap;
        justify-content: center;
    }
}