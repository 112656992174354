.dash_card .card h2 {
    color: var(--dark-primary-color);
}
.dashboard{
    margin: 5rem 0 0;
}
.boxes{
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
    padding: 0 1rem
}
.boxes .data_box{
    background: #272B44;
    border-radius: 8px;
    box-shadow: var(--box-shadow-1);
    /* border: 1px solid rgba(250, 250, 250, 0.24); */
    padding: 1rem 1.5rem;
    /* width: calc(25% - 4rem); */
    height: 100px;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem 1.5rem;
    height: 10rem;
    justify-content: space-evenly;
}
.dash_head{
    justify-content: flex-start;
    gap: 0.5rem;
}
.data_box h2{
    line-height: 0.8;
}
.react-daterange-picker__inputGroup__input,.react-daterange-picker__inputGroup__input svg{
    color: var(--dark-primary-color);
}

.light .react-daterange-picker__inputGroup__input{
    color: #272B44;
}
.react-daterange-picker__button svg{
    stroke: white;
}
.light .react-daterange-picker__button svg{
    stroke: #272B44;
}
.boxes .data_box h2{
    font-size: 2.6rem;
    font-weight: 900;
}
.boxes .data_box p,.boxes .data_box h2{
    margin: 0;
}
.boxes .data_box .decrease,.boxes .data_box .increase{
    font-size: 12px;
}
.boxes .data_box i{
    display: flex;
    align-items: center;
    font-size: 20px;
}
.light .boxes .data_box{
    box-shadow: var(--box-shadow-1);
    background: #fff;
    color: var(--box-text-light-primary);
    /* border: 1px solid rgba(0, 0, 0, 0.24); */
}
.boxes .data_box p{
    font-size: 14px;
}
.light .page_name{
    color: black;
    font-weight: 900!important;
}
.light .growth{
    color: var(--box-text-light-primary);
}
.growth span{
    cursor: pointer;
    position: relative;
}
.growth span:hover{
    color:#ffffff;
}
.light .growth span:hover{
    color:var(--table-text-light);
}
/* .growth span:hover:before{
    content: "";
    width: 100%;
    position: absolute;
    height: 4px;
    background: #ffffff;
    bottom: -10px;
} */

.dash_filter .filterbox>div{
    background: #272B44;
}
.light .dash_filter .filterbox>div{
    background: #ffffff;
    box-shadow: var(--box-shadow-1);
}
.piechart{
    padding: 0 1rem;
}
/* .light .growth span:hover:before{
    content: "";
    width: 100%;
    position: absolute;
    height: 4px;
    background: var(--table-text-light);
    bottom: -10px;
} */
.all_stats{
    margin: 0;
    display: flex;
    padding: 1rem 1rem 1rem;
    gap: 1rem;
    justify-content: center;
    flex-wrap: wrap;
    
}
.all_stats .search{
    width: 100%;
    margin: 0;
}
.all_stats .elem{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.all_stats .state-selection{
    margin: 0 0.5rem 0.5rem;
}
.apexcharts-legend-text{
    color: white!important;
}
.light .apexcharts-legend-text{
    color: var(--box-text-light-primary)!important;
}
.chart_table{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0 1rem;
    /* justify-content: center; */
    margin-top: 2rem;
    /* gap: 0.5rem; */
    margin-bottom: 1rem;
}
.chart_table .chart{
    width: 50%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    text-align: center;
}
.chart_table .cust_table{
    width: calc(50% - 1rem);
    display: flex;
    /* align-items: center; */
}
.dash_pie{
    width: calc(40% - 4rem);
}
.dash_stat{
    width: calc(40% - 2rem);
}
.dash_card{
    width:20%
}
.dash_bar{
    flex-direction: column;
    align-items: flex-start!important;
}

/* Calendar */
.react-calendar__tile:enabled:hover{
    border: 1px solid #9e9e9e;
    background-color: rgba(0, 0, 0, 0.04);
}
button.react-calendar__tile.react-calendar__tile--range.react-calendar__month-view__days__day {
    /* content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0; */
    background: var(--box-color-bg1)!important;
    /* z-index: 0; */
}
button.react-calendar__tile.react-calendar__tile--range.react-calendar__month-view__days__day:not(.react-calendar__tile--rangeStart):not(.react-calendar__tile--rangeEnd):before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    
    z-index: 0;
    
}
button.react-calendar__tile.react-calendar__month-view__days__day{
    padding: 0;
}
.react-calendar__month-view__days button {
    /* border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%; */
    border-radius: initial;
    height: 2.2rem;
    width: 3rem;
    position: relative;
    margin-bottom: 2px!important;
}
.react-calendar__month-view__days button:hover{
    border-radius: 50%;
}
button.react-calendar__tile--range.react-calendar__tile--rangeEnd.react-calendar__month-view__days__day{

    border-radius: 0;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}
button.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__month-view__days__day{

    border-radius: 0;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
}
button.react-calendar__tile--hoverStart{
    background: none;
}
button.react-calendar__tile--range.react-calendar__tile--rangeStart::before,button.react-calendar__tile--range.react-calendar__tile--rangeEnd::before{
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    background: var(--table-text-light)!important;
    z-index: 0;
    border-radius: 50%;
}
button.react-calendar__tile--range.react-calendar__tile--rangeStart abbr,button.react-calendar__tile--range.react-calendar__tile--rangeEnd abbr{
    color:rgb(232, 241, 252)!important
}
button.react-calendar__tile--range.react-calendar__tile--rangeStart:hover abbr,button.react-calendar__tile--range.react-calendar__tile--rangeEnd:hover abbr{
    color:var(--table-text-light)!important
}
button.react-calendar__tile--range.react-calendar__tile--now{
    border-radius: 0;
}
.react-calendar__tile--now:enabled{
    border-radius: 50%;
}
.react-calendar__tile--range.react-calendar__month-view__days__day:hover{
    border-radius: 0;
    border: none;
}
.react-calendar__tile--range.react-calendar__month-view__days__day:hover::before{
    background: var(--box-color-bg1)!important;
    border-radius: 50%;
}

.react-calendar--selectRange .react-calendar__tile--hover{
    border-radius:initial;
    border-width: 2px;
    border-style: dashed;
    border-color: rgb(0 0 0 / 26%) transparent;
    background: none!important;
}
button.react-calendar__tile--active.react-calendar__tile--hoverStart{
    /* border-image: initial; */
    border-radius: 50%;
    background: transparent!important;
}
button.react-calendar__tile--rangeBothEnds{
    border-radius: 50%;
}
.react-calendar__tile--hoverEnd{
    border-image: initial;
    border-radius: 50%;
}
.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform:capitalize!important;
    font-weight: normal!important;
    font-size: 0.8em!important;
    color: rgba(0, 0, 0, 0.6);
}
button.react-calendar__month-view__days__day--neighboringMonth{
    visibility: hidden;
    user-select: none;
}
.react-daterange-picker__calendar{
    width: 270px!important;
}
.react-calendar__month-view__weekdays{
    margin-bottom: 0.2rem;
}
.react-calendar__month-view__weekdays abbr[title]{
    text-decoration: none;
    cursor: text!important;
}
.react-calendar__month-view__days button abbr{
    /* border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%; */
    position: relative;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.8rem;
}
button.react-calendar__tile--active.react-calendar__tile--rangeEnd.react-calendar__tile--rangeStart{
    background: transparent!important;
}
.react-calendar__tile--active.react-calendar__tile--rangeEnd {
    background: #1976d2!important;
    border-radius: 50%;
    /* border-bottom-right-radius: 50%; */
}
.react-calendar__tile--active.react-calendar__tile--rangeStart{
    background: #1976d2!important;
    border-radius: 50%;
    /* border-bottom-left-radius: 50%; */
}
.react-calendar{
    box-shadow: 6px 4px 20px 0px rgb(0 0 0 / 36%);
    border: none;
    border-radius: 4px;
    padding: 0.5rem;
}
.react-calendar__tile--active {
    background: none!important;
    color: black!important;
}
.react-daterange-picker.react-daterange-picker--open.react-daterange-picker--enabled .react-daterange-picker__wrapper {
    border: 2.1px solid #1976d2!important;
}
.react-daterange-picker__inputGroup__input:invalid{
    background: none!important;
}
.react-daterange-picker__inputGroup {
    font-weight: 600!important;
}
.react-daterange-picker__wrapper{
    border-radius: 4px!important;
    padding: 0.5rem!important;
    height: 2.5rem!important;
    border: none!important;
    background: #272B44;
    box-shadow: var(--box-shadow-1);
    transition: 0.5s;
}
.light .react-daterange-picker__wrapper{
    background: white;
    transition: 0.5s;
}
.react-daterange-picker__wrapper input:focus-visible{
    outline: none;
}

@media screen and (max-width:900px){
    /* .boxes .data_box{
        width: 25%;
    } */
}
@media screen and (max-width:600px){
    /* .boxes .data_box {
        width: 40%;
    } */
}
@media screen and (max-width:448px){
    /* .boxes .data_box {
        width: 100%;
    } */
}
@media screen and (max-width:1000px){
    .chart_table{
        flex-direction: column;
    }
    .chart_table .chart{
        width: 100%;
    }
    .chart_table .cust_table{
        width: 100%;
    }
    .all_stats .dash_pie{
        width: 50%;
    }
    .all_stats .dash_stat{
        width: 48%;
    }
    .all_stats .dash_card{
        width: 100%;
    }
}
@media screen and (max-width:833px){
    .all_stats .dash_pie{
        width: 100%;
    }
    .all_stats .dash_stat{
        width: 100%;
    }
    .all_stats .dash_card{
        width: 100%;
    }
}
@media screen and (max-width:520px){
    .growth{
        flex-direction: column;
        justify-content: center;
    }
}

  .carousel-image{
    max-height: 400px;
    height: 95%;
    width: fit-content;
    border-radius: 5px;
    /* box-shadow: 2px 10px 5px rgba(0,0,0,0.3); */
    -webkit-box-shadow: 4px 6px 8px rgba(0,0,0,0.3);
  -moz-box-shadow: 4px 6px 8px rgba(0,0,0,0.3);
  box-shadow: 4px 6px 8px rgba(0,0,0,0.3);
    
  }
  .react-multi-carousel-item {
   margin-right: 16px;
   width: max-content !important;
  }
    