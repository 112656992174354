.home{
    /* height:-webkit-fill-available; */
    /* height: -webkit-fill-available;
    height: -moz-available; */
    display: flex;
    padding: 4.5rem 0.5rem 4rem;
    /* align-items: center; */
}
.stats_show{
    /* height: 0; */
    height: fit-content;
    width: 100%;
    display: flex;
    /* padding: 1rem 0; */
    flex-direction: column;
    gap: 1rem;
}
.home_stats{
    display: flex;
    justify-content: center;
    /* padding: 4rem 1rem 0; */
    width: -webkit-fill-available;
    width: -moz-available;;
    height: fit-content;
    max-height: -webkit-fill-available;
    max-height: -moz-available;
    overflow: auto;
    flex-direction: column;
}
.country{
    width: -webkit-fill-available;
    width: -moz-available;;
}
.country.light h2{
    color: var(--light-elem-color);
}
.state-selection{
    margin: 2rem 0 4rem;
}
.state-selection .dropdown select:focus {
    outline: none;
}
.dropdown{
    /* width: 50%; */
    margin: auto;
}
.chart-container{
    /* width: 80%; */
    /* margin: auto; */
    padding: 0 1rem;
    width: 100%;
    height: 100%;
}
.chart-container canvas{
    display: unset!important;
}
.state-selection .dropdown select {
    -webkit-appearance: none;
    appearance: none;
    background: var(--dark-bg);
    /* background-image: url(data:image/svg+xml;charset=utf-8,<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M0 6l12 12L24 6H0z" fill="gray"/><path fill="none" d="M0 0h24v24H0V0z"/></svg>); */
    background-position-x: calc(100% - .4rem);
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: .6rem;
    border-color: hsla(0,0%,58%,.10196078431372549);
    border-radius: 4px;
    color: #6c757d;
    cursor: pointer;
    font-weight: 600;
    padding: .5rem 1.4rem .5rem .5rem;
    width: 100%;
    font-family: 'poppins';
    transition: 0.5s;
}
.country.light .state-selection .dropdown select{
    border: 2px solid #e8e8e9;
    background: var(--light-bg);
}
.home_stats::-webkit-scrollbar {
    background: transparent;
    width: 2px;
}
/* 1036px */
@media screen and (max-width:1208px) {
    .home{
        flex-direction: column;
        gap: 1rem;
        padding: 4rem 0.8rem 5rem;
        height: fit-content;
    }
    .zoom{
        padding: 0!important;
        position: unset;
    }
    .hm-or2{
        order: 2;
    }
}
@media screen and (max-width:1080px) {
    .chart-container{
        width: 100%;
    }
}