.mapSvg{
    /* width: 75%; */
    height:fit-content!important;
    align-self: center;
    /* background-color: aqua; */
    /* color: white; */
    padding: 0 1rem;
    animation: fade 0.5s linear;
}


/* .paths:hover{
    stroke: #F67040;
    stroke-width: 1.5;
    position: relative;
    z-index: 2;
    stroke-opacity: 1;
} */


.zoom{
    width: 100%;
    /* height: -webkit-fill-available; */
    /* overflow-y: scroll; */
    
    /* padding: 4rem 0 0; */
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    justify-content: flex-start!important;
    /* position: sticky; */
    top: 0;
}

.wrapper .short-data{
    /* padding: 1rem; */
    width: -webkit-fill-available;
    width: -moz-available;
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    animation: fade 0.5s linear;
}
.wrapper .short-data p,h2{
    margin: 0;
}
.map-selector{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0.2rem 0 0 0;
}
.wrapper .states-data .map-selector span{
    font-size: 0.6rem;

}
.wrapper .states-data .map-selector .selectors{
    display: flex;
    gap: 1rem;
}
.map-selector .selectors{
    margin: 0.2rem 0 0;
}
.map-selector .selectors .camp-selector{
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    cursor: pointer;
    background: var(--selector-color-1);;
    
}
.selectors .camp-selector.active{
    background: var(--box-color-1);;
}
.map-selector .selectors .scan-selector{
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    cursor: pointer;
    background: var(--selector-color-2);
    
}
.selectors .scan-selector.active{
    background:  var(--box-color-2);
}
.map-selector .selectors .pcase-selector{
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    cursor: pointer;
    background: var(--selector-color-3);
    
}
.selectors .pcase-selector.active{
    background: var(--box-color-3);
}
.map-selector .selectors .districts-selector{
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    cursor: pointer;
    background: var(--selector-color-4);
    
}
.selectors .districts-selector.active{
    background: var(--box-color-4);
}
.map_tooltip{
    height: 1.5rem;
}
.map_tooltip p{
    background: var(--dark-primary-color);
    border-radius: 5px;
    color: var(--text-color)!important;
    padding:0.1rem 0.5rem;
    font-size: 0.8rem;
    user-select: none;
    margin: 0;
    margin-bottom: 4px;
}
text{
    font-size: 0.5rem;
    text-align: center;
} 
text:hover{
    display: block;
}
@media screen and (max-width:1285px) {
    .mapSvg{
        height: auto!important;
    }
}
.states-data{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
#maps{
    padding:0.2rem 0.5rem;
    font-size: 0.8rem;
    border: none;
    margin-bottom: 0.3rem;
}
#maps:focus-visible{
    outline: none;
}