@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('./assets/styles/colors.css');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: ; */
  font-family: 'Poppins', sans-serif!important;
  color: var(--dark-primary-color)!important;
  scroll-behavior: smooth;
  overflow-x: hidden;
  transition: 0.5s;
  user-select: none;
}
svg{
  box-sizing: initial;
}
.self-align{
  align-self: baseline;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.light>a>p{
  color: var(--light-elem-color);
}
.light input{
  color: var(--light-elem-color)!important;
}
.page{
  margin-top: 5rem!important;
  margin-bottom:1.5rem!important;
  /* height: 100vh; */
}
.page::-webkit-scrollbar{
  width: 0;
}
.page.pad-0{
  margin: 0;
}
body::-webkit-scrollbar {
  background: transparent;
  width: 2px;
}
body::-webkit-scrollbar-thumb {
  background-color: var(--dark-primary-color)!important;
  border-radius: 2px;
  width: 1rem;
}

@keyframes fade {
  0%{ opacity: 0 }
  100% { opacity: 1 }
}
@keyframes slideUp {
  0%{
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  
  }
}
.react-tooltip{
  background: var(--table-bg-dark)!important;
  box-shadow: var(--box-shadow-1);
  color: white!important;
}
.light .react-tooltip{
  background: white!important;
  color: black!important;
}
svg:focus {
  outline: none;
}