.detailsbox{
    background: #30344C;
    /* Dark/Outline - Border */
    padding: 0 1rem;
    border: 1px solid rgba(250, 250, 250, 0.24);
    border-radius: 8px;
    width: 159px;
    height: 159px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* margin: 1rem auto; */
    transition: 0.5s;
    cursor: pointer;
    box-shadow: var(--box-shadow-1);
}
.light .detailsbox{
    box-shadow: var(--box-shadow-1);
    background: #fff;
    color: var(--box-text-light-primary);
    border: 1px solid rgba(0, 0, 0, 0.24);
}
.detailsbox:hover{
    transition: none;
    border: 2px solid rgba(250, 250, 250, 0.4);
}
.light .detailsbox:hover{
    border: 2px solid rgba(0, 0, 0, 0.4);
}

.detailsbox .upperContent{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.detailsbox p{
    margin: 0;
}
.detailsbox .number{
    font-size: 24px;
    line-height: 24px;
}
.detailsbox .content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.detailsbox .content p{
    font-size: 16px;
    line-height: 24px;
}
.detailsbox .content span{
    font-size: 12px;
    line-height: 16px;
}
.detailsbox .report div{
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
}
.detailsbox .report span{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}
.change svg{
    width:13.5px;
    height:13.5px
}
.detailsbox .report .change{
    gap: 0.5rem;
}
.detailsbox .report{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.detailsbox .update{
    display: flex;
    align-items: center;
    gap: 1rem;
}
.detailsbox .update span{
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    position: relative;
    display: flex;
    width: 31px;
    height: 31px;
    justify-content: center;
    align-items: center;
}
.detailsbox .update p{
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* or 133% */

    letter-spacing: 2px;
}
.detailsbox .update span::after{
    content: "";
    position: absolute;
    background: rgba(250, 250, 250, 0.1);
    width: 23px;
    height: 23px;
    border-radius: 50%;
}
.detailsbox .update span::before{
    content: "";
    position: absolute;
    width: 31px;
    height: 31px;
    left: 0;
    border-radius: 50%;
    background: rgba(250, 250, 250, 0.1);
}
.light .detailsbox .update span::after{
    background: rgba(0, 0, 0, 0.1);
}
.light .detailsbox .update span::before{
    background: rgba(0, 0, 0, 0.1);
}