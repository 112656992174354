.state_page{
    /* height: -webkit-fill-available;
    height: -moz-available; */
    display: flex;
    padding: 4.5rem 1.5rem 0rem;
    animation: easepage 0.4s ease-out;
    /* align-items: center; */
}
.state_stats{
    width: 50%;
}
@keyframes easepage {
    0%{ 
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
}
@media screen and (max-width:1208px) {
    .state_page{
        flex-direction: column;
        gap: 1rem;
    }
    .state_page{
        height: auto;
        padding: 4.5rem 0.8rem 5rem;
    }
    .stats{
        margin: 0;
    }
    .statedisstat{
        margin: 0;
    }
    .state_stats{
        width: 100%;
    }
}