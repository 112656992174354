.loader {
    --loader-size: 35px;
    --loader-border-size: 3px;
    --loader-border-color: white;
    width: var(--loader-size);
    height: var(--loader-size);
    border: var(--loader-border-size) solid var(--loader-border-color);
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    background-color: transparent;
    border-radius: 50%;
    position: relative;
    animation: rotateX 1s infinite linear;
}
.light .loader{
    border: var(--loader-border-size) solid var(--dark-primary-bgcolor)!important;
    border-top-color: transparent!important;
    border-right-color: transparent!important;
    border-bottom-color: transparent!important;
    background-color: transparent;
}
.light .loader::before{
    border: var(--loader-border-size) solid var(--dark-primary-bgcolor)!important;
    border-top-color: transparent!important;
    border-right-color: transparent!important;
    border-bottom-color: transparent!important;
    background-color: transparent;
}

.loader::before {
    content: "";
    border: var(--loader-border-size) solid var(--loader-border-color);
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: transparent;
    background-color: transparent;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    animation: rotateX 0.5s infinite linear reverse;
}

@keyframes rotateX {
    from {
        transform: rotateZ(0deg);
    }

    to {
        transform: rotateZ(360deg);
    }
}