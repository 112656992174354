.sidebar{
    position: fixed;
    left: 0;
    flex-direction: column;
    display: flex;
    width: 111px;
    height: calc(100% - 4.1rem);
    align-items: center;
    background: #262940;
    border-right: 0.5px solid rgba(0, 0, 0, 0.2);
    z-index: 2;
    padding: 4rem 0;

    display: none;
}
.sidebar a{
    color: white;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
    width: 100%;
    
    background: #262940;
}
.sidebar a:hover{
    background: #393956;
    border-right: 0.5px solid rgba(0, 0, 0, 0.2);
    width: -webkit-fill-available;
    width: -moz-available;
}
.sidebar a img{
    width: 1.5rem;
}
.sidebar a.active{
    background: #393956;
    border-right: 0.5px solid rgba(0, 0, 0, 0.2);
    width: -webkit-fill-available;
    width: -moz-available;
}
.links{
    width: 100%;
}
.linksname{
    display: flex;
    flex-direction: column;
    height: -webkit-fill-available;
    height: -moz-available;
    position: absolute;
    top: 0;
    padding: 4rem 0;
    width: 20rem;
    left: 7rem;
    background: #262940;
    transform: translateX(-150%);
    opacity: 0;
    z-index: -999;
}
.toggmenu-links{
    position: fixed;
    left: 0;
    top: 4.3rem;
    display: flex;
    flex-direction: column;
    height: -webkit-fill-available;
    height: -moz-available;
    width: -webkit-fill-available;
    width: -moz-available;
    background: #262940;
    z-index: 4;
    /* Temporary */
    display: none;
}
.menu a{
    color: white;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
    width: 100%;
    background: #262940;
}
.menu a:hover{
    background: #393956;
    border-right: 0.5px solid rgba(0, 0, 0, 0.2);
    width: -webkit-fill-available;
    width: -moz-available;
}
.menu a.active{
    background: #393956;
    border-right: 0.5px solid rgba(0, 0, 0, 0.2);
    width: -webkit-fill-available;
    width: -moz-available;
}
.menu>div{
    cursor: pointer;
    user-select: none;
}
.linksname.active {
    opacity: 1;
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}
.linksname.inactive{
    animation: slide-out 0.5s forwards;
}
@keyframes slide-in {
    100% { transform: translateX(0%); }
}
@keyframes slide-out {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-150%); }
}
@media screen and (max-width:727px) {
    
}