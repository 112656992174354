:root{
    --dark-primary-bgcolor:rgb(22, 22, 37);
    /* --dark-bg : #1e1e30; */
    --dark-bg : #2E324A;
    /* --light-primary-bgcolor:rgb(235 235 235); */
    --light-primary-bgcolor:#f8fafc;
    /* --light-bg:rgb(242 242 242); */
    --light-box:#fff;
    --light-bg:#f8fafc;
    --dark-primary-color:#bdbdbd;
    --dark-hover:rgba(108,117,125,.06274509803921569);
    --box-color-1:rgb(0,123,255);
    --box-color-2:rgb(40,167,69);
    --box-color-3:rgb(255,7,58);
    --box-color-4:rgb(108,117,125);
    --box-color-bg1:rgba(0,123,255,.12549019607843137);
    --box-color-bg2:rgba(40,167,69,.12549019607843137);
    --box-color-bg3:rgba(255,7,58,.12549019607843137);
    --box-color-bg4:rgb(108 117 125 / 21%);
    --selector-color-1:rgba(0,123,255,.18823529411764706);
    --selector-color-2:rgba(40,167,69,.18823529411764706);
    --selector-color-3:rgba(255,7,58,.18823529411764706);
    --selector-color-4:rgb(108 117 125 / 32%);
    --box-shadow-1:0 10px 35px rgb(0 0 0 / 10%);
    --box-shadow-2:0px 6px 20px rgb(0 0 0 / 4%);
    --light-elem-color:#757575;
    --text-color:#161625;
    --light-selected-fill-1:rgb(0, 33, 49);
    --light-selected-fill-2:rgb(0, 59, 13);
    --light-selected-fill-3:rgb(57, 0, 25);
    --dark-selected-fill-1:rgb(138, 218, 255);
    --dark-selected-fill-2:rgb(159, 255, 181);
    --dark-selected-fill-3:rgb(255, 160, 201);
    /* --light-name-hover:#f8fafc; */
    --light-name-hover:rgba(0, 167, 228, 0.2);
    --box-text-light-primary:#64748b;
    /* --dark-name-hover:rgba(16, 16, 33, 0.3); */
    --dark-name-hover:rgba(0, 167, 228, 0.2);
    --border-1:2px solid rgba(108,117,125,0.06274509803921569);
    --table-bg-light:#f1f5f9;
    --table-bg-dark:#181827;
    --table-text-light:#4460f1;
}