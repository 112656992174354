.mapSvg{
    /* width: 75%; */
    max-height:90vh;
    align-self: center;
    /* background-color: aqua; */
    /* color: white; */
}


/* .paths:hover{
    stroke: #F67040;
    stroke-width: 2;
    position: relative;
    z-index: 2;
    stroke-opacity: 2;
} */


.zoom{
    width: 100%;
    /* height: -webkit-fill-available; */
    /* overflow-y: scroll; */
    /* padding: 4rem 0 0; */
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}
.map_tooltip{
    height: 1.5rem;
}

.map_tooltip p{
    background: var(--dark-primary-color);
    border-radius: 5px;
    color: var(--text-color)!important;
    padding:0.1rem 0.5rem;
    font-size: 0.8rem;
    user-select: none;
    margin: 0;
    margin-bottom: 4px;
}

/* .tooltip{
    width: 50px;
    height:30px;
} */

text{
    font-size: 0.5rem;
    text-align: center;
} 
text:hover{
    display: block;
}

.districtSvg{
    width: -webkit-fill-available;
    width: -moz-available;
    /* padding: 2rem 0; */
}
/* @media screen and (max-width: 1024px){
    .stats .camps, .scans, .pcases, .districts {
        width: 18%;
    }
} */
