.map-overlay {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 20px;
    overflow: auto;
    border-radius: 3px;
    padding: 0.8rem;
    border-radius: 6px;
  }
  .map-overlay.dark{
    background: #2e324a;
    
  }
  .map-overlay.dark span:nth-child(2){
    color: white!important;
  }
  .map-overlay.light{
    background: #fff;
  }
  #map {
    position: relative;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 82vh;
    border-radius: 6px;
    margin-top: 1rem;
  }
  #features {
    top: 0;
    height: max-content;
    margin-top: 20px;
    width: 250px;
  }
  #legend {
    padding: 10px;
    box-shadow: var(--box-shadow-1);
    line-height: 18px;
    /* height: 150px; */
    margin-bottom: 40px;
    width: 100px;
  }
  .legend-key {
    display: inline-block;
    border-radius: 20%;
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
  #legend span:nth-child(2){
    font-size: 0.8rem;
    color: black;
    font-weight: 500;
  }

  .zoom-slider{
    position: absolute;
    bottom: 0;
    margin-bottom: 40px;
    margin-left: 20px;
    left: 0;
    width: 200px;
    box-shadow: var(--box-shadow-1);
    font-size: 0.8rem;
    font-weight: 500;
  }
  .zoom-slider input{
    width: 100%;
  }