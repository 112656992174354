.filterbox {
    display: flex;
    gap: 0.5rem;
    transition: 0.5s;
    margin-top: 0.5rem;
    flex-wrap: wrap;
    justify-content: center;
}

.light .filterbox div {
    background: #ffffff;
    box-shadow: var(--box-shadow-1);
}

.light .filterbox div span {
    color: var(--light-elem-color);
}

.light .filterbox>div:hover{
    background: var(--box-color-bg1);
}
.light .filterbox div:hover span,
.light .filterbox div:hover svg {
    color: var(--table-text-light) !important
}

.light .filterbox div.active {
    background: var(--box-color-bg1)!important;

}
.filterbox div.active{
    color: white!important;
}
.light .filterbox div.active span {
    color: var(--table-text-light) !important;
}

.filterbox div:hover span,
.filterbox div:hover svg {
    color: white;
}

.light .filterbox div.active svg {
    color: var(--table-text-light) !important;
}

.filterbox div.active {
    background: var(--box-color-bg1)!important;
}

.filterbox>div {
    width: 2.5rem;
    height: 2.5rem;
    background: var(--table-bg-dark);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    box-shadow: var(--box-shadow-1);
    transition: 0.5s;
}

.filterbox div svg {
    width: 16px;
    height: 16px;
}

.filterbox div span {
    font-size: 12px;
    font-weight: 600;
    line-height: 6px;
    position: absolute;
    /* right: 5px;
    top: 5px; */
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.filterbox .filter_tooltip {
    top: 3rem;
    left: -0.5rem;
    z-index: 999;
    background: black;
    width: 10rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;

}

.filterbox span.filter_tooltip {
    color: white !important;
}

.filterbox .filter_tooltip::before {
    content: "";
    position: absolute;
    left: 1rem;
    top: -0.4rem;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid black;
}
.react-tooltip{
    z-index: 20;
}
.light .react-tooltip{
    color: var(--light-elem-color);
}