.radicalchartview{
    background: #272B44;
border-radius: 6px;
box-shadow: var(--box-shadow-1);
transition: 0.5s;
}
.light .radicalchartview{
    box-shadow: var(--box-shadow-1);
    background: #fff;
    color: var(--box-text-light-primary);
}
.radicalchartview p{
    margin: 0;
}
.radicalchartview .title{
    display: flex;
    padding: 1rem;
    justify-content: space-between;
}
.radicalchartview .title .titleItem{
    display: flex;
    gap: 12px;
    align-items: baseline;
}
.radicalchartview .title .viewDetails{
    background: #1C203A;
border: 1px solid #464855;
border-radius: 90px;
width: 89px;
height: 26px;
display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;
}
.light .radicalchartview .title .viewDetails{
    box-shadow: var(--box-shadow-1);
    background: rgba(0, 0, 0, 0.1);
    color: var(--box-text-light-primary);
}
.radicalchartview .title .viewDetails p{
    font-weight: 400;
font-size: 12px;
line-height: 16px;
}
.radicalchartview .title p{
    font-weight: 400;
font-size: 16px;
line-height: 24px;
}
.radicalchartview .title span{
    font-weight: 500;
font-size: 10px;
line-height: 16px;
text-transform: uppercase;
}
@media screen and (max-width:480px){
    .radicalchartview .title{
        flex-direction: column;
    align-items: center;
    gap: 14px;
    }
}