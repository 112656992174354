/* .statstates{
    position: absolute;
    bottom: 2rem;
    left: 2rem;
} */
.statehead p {
    margin: 0;
}

.statstates>p {
    margin-top: 0;
}

.stats {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    font-weight: 700;
    position: relative;
    margin: 0rem 0.5rem;
}

.stats .camps,
.scans,
.pcases,
.districts {
    display: flex;
    /* background: rgba(165, 170, 220, 0.2); */
    backdrop-filter: blur(3.5px);
    border-radius: 5px;
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 132px;
    cursor: pointer;
    user-select: none;
    justify-content: center;
    transition: ease-out 0.5s;
    animation: fade 0.5s linear;
    justify-content: space-evenly;
    box-sizing: initial;
    min-height: 10vh;
}

/* .sliding_active{
    width: 132px;
    height: 120px;
    position: absolute;
    left: 0;
    background: black;
    transform: translate3d(132px, 0, 0);
    opacity: 1;
    top: 0;
    border-radius: 5px;
} */
.camps {
    color: var(--box-color-1) !important;
}

.scans {
    color: var(--box-color-2) !important;
}

.pcases {
    color: var(--box-color-3) !important;
}

.districts {
    color: var(--box-color-4) !important;
}

.camps.map-active {
    background: var(--box-color-bg1);
    transition: ease-out 0.5s;
}

.scans.map-active {
    background: var(--box-color-bg2);
    transition: ease-out 0.5s;
}

.pcases.map-active {
    background: var(--box-color-bg3);
    transition: ease-out 0.5s;
}
.districts.map-active {
    background: var(--box-color-bg4);
    transition: ease-out 0.5s;
}

.camps:hover {
    background: var(--box-color-bg1);
}

.scans:hover {
    background: var(--box-color-bg2);
}

.pcases:hover {
    background: var(--box-color-bg3);
}

.districts:hover {
    background: var(--box-color-bg4);
}

.stats p {
    padding: 0;
    margin: 0;
    font-size: 0.8rem;
}

.stats h1 {
    margin: 0;
}

.statedisstat {
    /* position: absolute;
    bottom: 2rem;
    right: 2rem; */
    box-shadow: var(--box-shadow-1);
    backdrop-filter: blur(7.5px);
    width: -webkit-fill-available;
    width: -moz-available;
    height: fit-content;
    /* max-height: 24rem; */
    /* max-height: -webkit-fill-available; */
    border-radius: 6px;
    padding: 1rem 0 2px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    background: var(--dark-bg);
    gap: 1rem;
    margin: 0 0.5rem 0.5rem;
    transition: 0.5s;
    animation: slideUp 0.5s ease-out
}

.statedisstat.light {
    background: var(--light-box);
}

.statedisstat.light p,
svg {
    color: var(--light-elem-color);
}



.statehead {
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

/* .statedist {
    height: 290px; 
     margin: 0 0.5rem;
    overflow: auto;
} */

.table-scroll::-webkit-scrollbar {
    background: transparent;
    width: 2px;
    height: 4px;
}

.table-scroll::-webkit-scrollbar-thumb {
    background-color: var(--dark-primary-color) !important;
    border-radius: 2px;
    width: 1rem;
}

.data {
    display: flex;
    gap: 4px;
    width: -webkit-fill-available;
    width: -moz-available;
    flex-direction: row-reverse;
    /* margin: 0.5rem; */
    align-items: center;
    justify-content: flex-start;
    /* margin: 0.5rem; */
}

.change {
    display: flex;
    align-items: center;
    gap: 4px;
}

.data .change svg {
    font-weight: 900;
    font-size: 14px;
    height: 10px;
    width: auto;
}

.data .change span {
    font-size: 10px;
    font-weight: 900;
}

.increase svg,
.increase span {
    color: #06949A;
    flex-shrink: 0;
}

.decrease svg,
.decrease span {
    color: #F2346B;
    flex-shrink: 0;
}

.data p {
    margin: 0;
    /* width: -webkit-fill-available!important;
    width: -moz-available !important; */
}

.data img {
    height: 1rem;
}

.name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;

    padding: 0;
}

.statedisstat .name:hover {
    background: var(--dark-name-hover);
    border-left: 1px solid var(--dark-primary-color);
}

.statedisstat.light .name:hover {
    background: var(--light-name-hover);
}

.statedisstat.light .name.light:hover>p {
    color: var(--table-text-light);
}

div.header_list span:first-child {
    margin: 0;
    width: 40%;
    position: sticky;
    left: 0;
    padding: 0.5rem;
    z-index: 11;
    background: var(--table-bg-dark);
    transition: 0.5s;
    padding: 0.5rem 1rem;
}

.header_list {
    background: var(--dark-primary-bgcolor);
    position: sticky;
    width: -webkit-fill-available;
    width: -moz-available;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    padding: 0;
    top: 0;
    transition: 0.5s;
}

.header_list .data span {
    width: -webkit-fill-available !important;
    width: -moz-available !important;
}

.header_list.light {
    background: var(--table-bg-light);
    color: var(--light-elem-color);
    ;

}

div.header_list.light span:first-child {
    background: var(--table-bg-light) !important;
    color: var(--table-text-light) !important;
}

/* .header_list:hover{
    background: none!important;
    border-left: none!important
} */
/* .header_list span {
    font-size: 14px;
    font-weight: 700;
}

div.name p:first-child {
    margin: 0;
    width: 35%;
    position: sticky;
    left: 0;
    padding: 0.5rem;
    z-index: 11;
    padding: 0.5rem 1rem;
}

div.name>p {
    padding: 0;
    background: var(--table-bg-dark);
    font-weight: 700;
    transition: 0.5s;
    box-sizing: initial;
}

div.name.light>p {
    background: var(--table-bg-light);
    color: var(--box-text-light-primary);
}

.row_data {
    width: -webkit-fill-available;
    width: -moz-available;
    display: flex;
    flex-grow: 0;
    flex-direction: row;
    justify-content: space-between;
    z-index: 1;
    text-align: end;
    padding: 0 0.5rem 0 0;
} */

.total_list {
    display: inline-flex;
    flex-direction: column;
    max-width: 100vw;
    width: -webkit-fill-available;
    width: -moz-available;
}

.city_name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
}

.table-scroll {
    position: relative;
    width: 100%;
    z-index: 1;
    margin: auto;
    overflow: auto;
    max-height: 388px;
    scroll-behavior: smooth;
}

.table-scroll table {
    width: 100%;
    min-width: 460px;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
}

.table-wrap {
    position: relative;
}

.table-scroll th,
.table-scroll td {
    padding: 5px 10px;
    /* background: #fff; */
    vertical-align: middle;
    text-align: end;
}

.table-scroll thead th {
    /* color: #fff; */
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: var(--table-bg-dark);
    transition: 0.5s;
    text-align: end;
}
.light .table-scroll thead th{
    background: var(--table-bg-light) !important;
    color: var(--table-text-light) !important;
    transition: 0.5s;
}

th:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
    background: var(--table-bg-dark);
    transition: 0.5s;
    text-align: start;
    width: 12rem;
    vertical-align: middle;
    max-width: 150px;
    word-wrap: break-word;
}
.main-table tbody tr{
    cursor: pointer;
}
.main-table tbody tr:hover{
    background: var(--dark-name-hover);
    border-left: 1px solid var(--dark-primary-color);
}
.light th:first-child{
    background: var(--table-bg-light);
    color: var(--box-text-light-primary)
}
.light .main-table tbody tr:hover th:first-child{
    color: var(--table-text-light);
}
.light .table-scroll td{
    color: var(--light-elem-color);
}
.table_data{
    display: flex;
    justify-content: flex-start;
    gap: 0px;
    flex-direction: column-reverse;
    align-items: flex-end;
}
.table_data p{
    margin: 0;
}
.table_data .change{
    font-size: 10px;
}
.table_data .change svg{
    width: 10px;
    height: 10px;
}
thead th:first-child {
    z-index: 5;
    text-align: start;
}

/* @media screen and (max-width:1208px) {
    .total_list {
        width: 100vw;
    }
} */

@media screen and (max-width:1024px) {
    .statstates {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .stats {
        justify-content: center;
        /* flex-wrap: wrap; */
        width: -webkit-fill-available;
        width: -moz-available;
    }

    /* .stats .camps,.scans,.pcases,.districts{
        width: 18%!important;
    } */
}

@media screen and (max-width:641px) {
    .stats {
        flex-wrap: wrap;
    }
}

@media screen and (max-width:820px) {
    div.name p:first-child {
        min-width: 35%;
        width: auto;
    }

    div.header_list span:first-child {
        min-width: 35.5%;
        width: auto;
    }

    .stats .camps,
    .scans,
    .pcases,
    .districts {
        width: 40% !important;
    }
}

@media screen and (max-width:479px) {

    .stats .camps,
    .scans,
    .pcases,
    .districts {
        width: 38% !important;
    }
}

@media screen and (max-width:413px) {

    .stats .camps,
    .scans,
    .pcases,
    .districts {
        width: 35% !important;
        ;
    }
}

@media screen and (max-width:346px) {

    .stats .camps,
    .scans,
    .pcases,
    .districts {
        width: 30% !important;
    }
}