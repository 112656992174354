.footer{
    width: -webkit-fill-available;
    width: -moz-available;
    /* position: fixed; */
    bottom: 0;
    background: var(--dark-bg)!important;
    padding: 1rem;
    box-shadow: var(--box-shadow-1);
    text-align: left;
    transition: 0.5s;
}
.footer p{
    font-size: 0.85rem;
}
.footer .copyright p{
    color: var(--dark-primary-color);
    margin: 0;
    font-weight: 700;
    font-size: 0.85rem;
}
.footer .copyright p a{
    text-decoration: none;
    color: var(--light-primary-bgcolor);
}
.footer.light{
    background: var(--light-bg)!important;
}
.footer.light p{
    color: var(--light-elem-color);
}
.footer.light p a{
    color: var(--text-color);
}