.search input{
    border: 0;
    border-radius: 5px;
    box-shadow: var(--box-shadow-1);
    color: var(--dark-primary-color);
    font-size: 1rem;
    height: 2rem;
    outline: none;
    padding: 0.5rem 0.5rem 0.5rem 3rem;
    width: 100%;
    background: #1e1e30;
    transition: 0.5s;
    box-sizing: initial;
}
.search svg{
    position: absolute;
    padding: 0 0 0 1rem;
    width: 1.5rem;
    height: 1.5rem;
    color: var(--dark-primary-color);
    box-sizing: initial;
}
.search{
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    animation: fade 0.5s linear;
    /* margin: 0 0.5rem; */
}
.search .results {
    margin: 0.5rem 0.5rem;
    width: -webkit-fill-available;
    width: -moz-available;
    max-height: 10rem;
    overflow: auto;
}
.search .results::-webkit-scrollbar {
    background: transparent;
    width: 2px;
}
.search .results::-webkit-scrollbar-thumb {
    background-color: var(--dark-primary-color)!important;
    border-radius: 2px;
    width: 1rem;
}
.search .results a{
    text-decoration: none!important;
}
.search .results .result{
    border-bottom: var(--border-1);
    color: var(--dark-primary-color);
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    font-size: .9rem;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 2rem;
    padding: 1rem;
    transition: all .2s ease-in-out;
    align-items: center;
}
.search .results .result:hover{
    background: var(--dark-hover);
}
.search .search-box{
    width: -webkit-fill-available;
    width: -moz-available;
    width: 100%;
    display: flex;
    align-items: center;
}
.search p{
    width: -webkit-fill-available;
    width: -moz-available;
    text-align: initial;
    color: var(--light-primary-bgcolor);
    font-size: 0.8rem;
}